import React from "react"
import Layout from "../components/layout"
import "../styles/terms.scss"
export default function DeleteUser() {
  return (
    <Layout>
        <div className="terms">
            <h1>User Account Deletion in the Utekoll App</h1>
            <p>English version, see Swedish version below</p>

            <p>To delete your account in Utekoll's App you open the Profile Page in the App. In the Account tab you find the 'Delete User Profile' button.</p>

            <p>All the data you have previously provided in your Profile page is deleted: email, name, profile image and profile biography.</p>
            
            <p>Activity reports that you may have created <em>are not</em> automatically deleted. They are still visible to other users but without any personal information (name, profile image). Note however that report images you have provided are also still visible in the reports.</p>

            <p>To also delete your reports as part of the account deletion you delete them prior to deleting your account, in the Profile Page under the 'Reports' tab.</p>

            <p>You may also contact us (contact info at www.utekoll.se) and we will delete both your account and your activity reports for you.</p>
        </div>

        <div className="terms">
        <h1>Borttagning av användarkonto i Utekoll-appen</h1>

        <p>För att ta bort ditt konto i Utekolls app öppnar du Profilsidan i appen. Under Kontotabben hittar du knappen 'Ta bort användarprofil'.</p>

        <p>Alla data du tidigare har lämnat på din Profilsida kommer att raderas: e-post, namn, profilbild och profilbiografi.</p>

        <p>Aktivitetsrapporter som du eventuellt har skapat <em>tas inte bort</em> automatiskt. De är fortfarande synliga för andra användare men utan någon personlig information (namn, profilbild). Observera dock att rapportbilder du har tillhandahållit fortfarande är synliga i rapporterna.</p>

        <p>För att även ta bort dina rapporter som en del av kontoborttagningen, raderar du dem innan du tar bort ditt konto, på Profilsidan under fliken 'Rapporter'.</p>

        <p>Du kan också kontakta oss (kontaktinformation på www.utekoll.se) så tar vi bort både ditt konto och dina aktivitetsrapporter åt dig.</p>
        </div>
    </Layout>
  )
}